<template>
  <div class="card card-light mb-1">
    <div class="card-header p-2">
      <h4 class="card-title w-100 p-0">
        <a
          class="d-block w-100 collapsed"
          data-toggle="collapse"
          :href="`#collapse${det.id}`"
          aria-expanded="false"
        >
          <div class="row justify-content-between">
            <div v-if="resumen.length > 0" class="col-sm-1 text-center">
              <span class="badge bg-info text-bold text-sm">
                {{ resumen[0].equipo.placa }}</span
              >
            </div>
            <div class="col-sm-3">
              <div class="header-resum">
                <h3 class="header-des text-bold text-sm">
                  <i class="fas fa-truck"></i>
                  {{
                    det.tipEquipoVehiculo
                      ? det.tipEquipoVehiculo
                      : det.tipEquipoEquipo
                  }}
                  {{
                    det.ltipo_remolque
                      ? " - " + det.ltipo_remolque.descripcion
                      : ""
                  }}
                </h3>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="header-resum">
                <h3 class="header-des text-bold">
                  <i class="fas fa-calculator text-sm mr-1"></i>
                  <span class="text-sm mr-2">Cant Servicios:</span>
                  <span class="badge bg-lightgreen mr-1">
                    {{ det.totalOperativo }}</span
                  >
                  <span
                    v-if="det.totalDisponible"
                    class="badge bg-lightyellow mr-1"
                  >
                    {{ det.totalDisponible }}</span
                  >
                  <span class="badge bg-lightred mr-1">
                    {{ det.totalFuera_operacion }}</span
                  >
                </h3>
              </div>
            </div>
          </div>
        </a>
      </h4>
    </div>
    <div
      :id="`collapse${det.id}`"
      class="collapse"
      data-parent="#accordion"
      style=""
    >
      <table
        class="table table-bordered table-sm m-0"
        style="font-size: 0.85em"
      >
        <thead class="bg-frontera text-white text-center">
          <tr>
            <th rowspan="2" class="pl-1">
              <label>#</label>
            </th>
            <th rowspan="2" class="pl-1">
              <label for="">Servicio</label>
              <input
                v-model="buscarResum.servicio"
                class="form-control form-control-sm"
                placeholder="N° Servicio"
              />
            </th>
            <th rowspan="2">
              <label for="">Vehículo / Equipo</label>
              <input
                v-model="buscarResum.vhEqu"
                class="form-control form-control-sm"
                placeholder="Placa"
              />
            </th>
            <th rowspan="2">
              <label for="">Documento Conductor</label>
              <input
                v-model="buscarResum.condApa"
                type="number"
                class="form-control form-control-sm"
                placeholder="Documento"
              />
            </th>
            <th rowspan="2">
              <label for="">Fecha Inicio</label>
              <input
                v-model="buscarResum.fechaIni"
                type="date"
                class="form-control form-control-sm"
              />
            </th>
            <th rowspan="2">
              <label for="">Fecha Fin</label>
              <input
                v-model="buscarResum.fechaFin"
                type="date"
                class="form-control form-control-sm"
              />
            </th>
            <th class="text-center" colspan="3">Reporte Trabajo</th>
            <th class="text-center" rowspan="2">Hora Adicional</th>
            <th class="text-center" rowspan="2">
              Movilización - Desmovilización
            </th>
            <th class="text-center" colspan="3">Tarifa</th>
          </tr>
          <tr class="text-center">
            <th>
              Operativo
              <small class="description-text text-secundary"> (Horas) </small>
            </th>
            <th>
              Disponible
              <small class="description-text text-secundary"> (Horas) </small>
            </th>
            <th>
              Fuera Operación
              <small class="description-text text-secundary"> (Horas) </small>
            </th>
            <th class="text center m-0">Costo Prod/Mant</th>
            <th class="text center m-0">Costo Add</th>
            <th class="text center m-0">Costo Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(resum, indiceResum) in filteredRowsDetail"
            :key="indiceResum"
          >
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              {{ indiceResum + 1 }}
            </td>
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              {{ resum.id }}
            </td>
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              {{ resum.equipo.placa }}
            </td>
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              {{ resum.persona.numero_documento }}
            </td>
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              {{ resum.fecha_ini }}
            </td>
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              {{ resum.fecha_fin }}
            </td>
            <td
              class="text-center bg-lightgreen"
              v-if="shouldShowCell(resum, det)"
            >
              <strong>
                {{ resum.operativo ? resum.operativo : "0" }}
              </strong>
            </td>
            <td
              class="text-center bg-lightyellow"
              v-if="shouldShowCell(resum, det)"
            >
              <strong>
                {{ resum.disponible ? resum.disponible : "0" }}
              </strong>
            </td>
            <td
              class="text-center bg-lightred"
              v-if="shouldShowCell(resum, det)"
            >
              <strong>
                {{ resum.fuera_operacion ? resum.fuera_operacion : "0" }}
              </strong>
            </td>
            <td class="text-center" v-if="shouldShowCell(resum, det)">
              <strong>
                {{ resum.adicionales ? resum.adicionales : "0" }}
              </strong>
            </td>
            <td v-if="shouldShowCell(resum, det)"></td>
            <td class="text-right" v-if="shouldShowCell(resum, det)">
              <template v-if="resum.costoTrabajo.length > 0">
                <div
                  class="text-bold"
                  v-for="(costoTrabajo, index) in resum.costoTrabajo"
                  :key="index"
                >
                  <strong>{{ costoTrabajo }}</strong>
                </div>
              </template>
              <strong v-else>$ 0</strong>
            </td>
            <td class="text-right" v-if="shouldShowCell(resum, det)">
              <template v-if="resum.costoAdd.length > 0">
                <div
                  class="text-bold"
                  v-for="(costoAdd, index) in resum.costoAdd"
                  :key="index"
                >
                  <strong>{{ costoAdd }}</strong>
                </div>
              </template>
              <strong v-else>$ 0</strong>
            </td>
            <td class="text-right" v-if="shouldShowCell(resum, det)">
              <strong>
                {{ resum.costoTotal ? resum.costoTotal : "$ 0" }}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CsResumenServicio",

  props: {
    det: {
      type: Object,
    },
  },

  data() {
    return {
      resumen: {},
      buscarResum: {
        servicio: "",
        vhEqu: "",
        condApa: "",
        fechaIni: "",
        fechaFin: "",
        turno: "",
      },
    };
  },

  computed: {
    filteredRowsDetail() {
      if (this.resumen.length > 0) {
        return this.resumen.filter((res) => {
          /* Resultado de res */
          const idServ = res.id.toString().toLowerCase();
          const vhEqui = res.equipo.placa.toLowerCase();
          const condApa = res.persona.numero_documento.toLowerCase();
          const fechaIni = res.fecha_ini.toLowerCase();
          const fechaFin = res.fecha_fin.toLowerCase();

          /* Constantes modelos de los inputs */
          const searchTermIdServ = this.buscarResum.servicio.toLowerCase();
          const searchTermvh = this.buscarResum.vhEqu.toLowerCase();
          const searchTermcondApa = this.buscarResum.condApa.toLowerCase();
          const searchFechaIni = this.buscarResum.fechaIni.toLowerCase();
          const searchFechaFin = this.buscarResum.fechaFin.toLowerCase();
          return (
            vhEqui.includes(searchTermvh) &&
            condApa.includes(searchTermcondApa) &&
            fechaIni.includes(searchFechaIni) &&
            fechaFin.includes(searchFechaFin) &&
            idServ.includes(searchTermIdServ)
          );
        });
      } else {
        return this.resumen;
      }
    },
  },

  methods: {
    getIndex() {
      if (this.$parent.resumen.length > 0) {
        let totalOperativo = 0;
        let totalDisponible = 0;
        let totalFuera_operacion = 0;

        // Se filtra el contenido a mostrar
        this.resumen = this.$parent.resumen.filter((res) => {
          return (
            res.equipo_type === this.det.vehiculo_equipo_type &&
            res.equipo_id === this.det.vehiculo_equipo_id
          );
        });

        for (let i = 0; i < this.resumen.length; i++) {
          let resumen = this.resumen[i];

          /* Detalles Servicios */
          this.resumen[i].operativo = 0;
          this.resumen[i].disponible = 0;
          this.resumen[i].fuera_operacion = 0;
          this.resumen[i].adicionales = 0;
          for (let j = 0; j < resumen.detalle_servicios.length; j++) {
            if (resumen.detalle_servicios[j].tipo_hora == 1) {
              switch (resumen.detalle_servicios[j].tipo_tiempo) {
                case 1:
                  this.resumen[i].operativo += parseInt(
                    resumen.detalle_servicios[j].cant_horas
                  );
                  break;
                case 2:
                  this.resumen[i].disponible += parseInt(
                    resumen.detalle_servicios[j].cant_horas
                  );
                  break;
                case 3:
                  this.resumen[i].fuera_operacion += parseInt(
                    resumen.detalle_servicios[j].cant_horas
                  );
                  break;
                default:
                  break;
              }
            } else {
              this.resumen[i].adicionales = parseInt(
                resumen.detalle_servicios[j].cant_horas
              );
            }
          }

          /* Tarifas Servicios */
          let flagCostoTrabajo = [];
          let flagCostoAdd = [];
          let valorTotal = 0;
          for (let k = 0; k < resumen.tarifas.length; k++) {
            // Se suma el valor de las tarifas
            let valor_base = resumen.tarifas[k].valor_base;
            valorTotal += parseFloat(valor_base);
            if (
              resumen.tarifas[k].cs_det_tarifa_type ==
              "App\\CsDetTarifasTrabajos"
            ) {
              flagCostoTrabajo.push(
                this.formatCurrency("es-CO", "COP", 2, valor_base)
              );

              if (resumen.tarifas[k].clase_tarifa == 2) {
                totalOperativo++;
              }
              if (resumen.tarifas[k].clase_tarifa == 1 && resumen.tarifas[k].cs_det_tarifa_id) {
                totalDisponible++;
              }
              if (!resumen.tarifas[k].cs_det_tarifa_id) {
                totalFuera_operacion++;
              }
            } else {
              flagCostoAdd.push(
                this.formatCurrency("es-CO", "COP", 2, valor_base)
              );
            }
          }

          this.resumen[i].costoTrabajo = flagCostoTrabajo;
          this.resumen[i].costoAdd = flagCostoAdd;
          this.resumen[i].costoTotal = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            parseFloat(valorTotal)
          );
        }

        this.det.totalOperativo = totalOperativo;
        this.det.totalDisponible = totalDisponible;
        this.det.totalFuera_operacion = totalFuera_operacion;
      }
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    shouldShowCell(resum, det) {
      if (resum.remolque) {
        return (
          (resum.equipo.tipo_equipo == det.tipo_vehiculo_equipo ||
            (resum.equipo.tipo_vehiculo_id == det.tipo_vehiculo_equipo &&
              resum.remolque.tipo_remolque == det.tipo_remolque)) &&
          resum.equipo_type == det.vehiculo_equipo_type &&
          resum.equipo_id == det.vehiculo_equipo_id
        );
      } else {
        return (
          (resum.equipo.tipo_equipo == det.tipo_vehiculo_equipo ||
            resum.equipo.tipo_vehiculo_id == det.tipo_vehiculo_equipo) &&
          resum.equipo_type == det.vehiculo_equipo_type &&
          resum.equipo_id == det.vehiculo_equipo_id
        );
      }
    },
  },

  async mounted() {
    this.getIndex();
  },
};
</script>
<style>
.bg-lightred {
  background-color: #ffcccc; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}

.bg-lightgreen {
  background-color: #e5f7e0; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}
.bg-lightyellow {
  background-color: #ffffcc; /* Color amarillo claro */
}
.header-resum {
  display: block;
  margin: 0px 0;
  padding: 2px;
  text-align: left;
}
.header-des {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.pvtTable {
  width: 100%;
}
</style>
